@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-address-map-button {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  color: white;
  background-color: $blue1;
  width: fit-content;
  border-radius: $border-radius;

  &:hover {
    background-color: $blue2;
    cursor: pointer;
  }

  &--delivered {
    color: $gray3;
  }

  &__icon {
    margin-right: 6px;
    font-size: 16px;
  }

  &__text {
    text-transform: uppercase;
    font-size: 12px;
  }
}
