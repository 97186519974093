@import "./../../styles/variables.scss";

.xgs-select {
  &__control {
    cursor: text;
    font-size: 14px !important;
    font-family: "Roboto-Regular" !important;
    outline: none;
    height: auto !important;

    .xgs-labeled-input-failed & {
      border-color: #EB5757;
    }

    &--is-focused {
      box-shadow: 0 0 0 2pt rgba(52, 182, 255, 0.5) !important;
      border: 1px solid $gray4 !important;
    }

    .xgs-tracking-filter__input--multi & {
      height: auto;
    }
  }

  &__value-container {
    cursor: text;
  }

  &__placeholder {
    cursor: text;
  }

  &__indicator {
    padding: 8px 2px !important;
  }
}