@import "./../../../styles/variables.scss";

.xgs-probill-accordion {
  &__button {
    position: relative;
    background-color: $gray6;
    font-weight: bold;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-size: 17px;
    &[aria-expanded='true'] {
      font-size: 18px;
    }
    &:active {
      border: 1px solid $blue3;
    }
    &:hover {
      background-color: $gray5;
    }
    &:before {
      content: '';
      height: 10px;
      width: 10px;
      margin-right: 30px;
      margin-left: 2px;
      border-bottom: 2px solid ;
      border-right: 2px solid ;
      transform: rotate(-45deg);
      transition: 0.4s;
    }
    &[aria-expanded='true']::before,
    &[aria-selected='true']::before {
      transform: rotate(45deg);
    }
    span {
      font-weight: normal;
    }
    &__exception-text {
      color: $red;
      margin-left: 4px;
    }
  }
  &__heading {
    font-size: 17px;
  }
  &__panel {
    background-color: white;
    animation: fadein 0.35s ease-in;
    border-left: 1px solid $gray7;
    border-right: 1px solid $gray7;
    >* {
      border-bottom: 1px solid $gray7;
    }
  }
}

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}