@import "./variables.scss";

/* responsiveness */
@mixin up-to($width) {
  @media only screen and (max-width: $width - 1) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: $mobile-width + 1px) and (max-width: $tablet-width) {
    @content;
  }
}
/* /responsiveness */
