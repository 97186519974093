@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

$tablet : 601px;

.xgs-consignee-accordion {
  padding: 0;
  display: block;
  color: $gray0;
  margin-bottom: $margin7;
  &--delivered {
    color: $gray3;
  }
  &__button {
    background-color: $blue3;
    color: $gray0;
    width: 100%;
    padding: 20px;
    min-height: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: $border-radius;

    &__text {
      display: block;
      &__consignee {
        font-size: 24px;
      }
      &__number {
        font-size: 18px;
        margin-top: 5px;
        color: $gray2;
        // @include up-to($tablet) {
        //   display: none;
        // }
        &__refused {
          color: $red
        }
      }
    }

    &__mark {
      border: none;
      font-size: 18px;
      transition: all 0.4s;
      margin-left: auto;
      padding: 0;
    }

    &:hover {
      background-color: $blue4;
    }
    &:active {
      outline: 1px solid $blue3;
    }

    &:before {
      // float: right;
      content: '';
      height: 15px;
      width: 15px;
      margin-right: 16px;
      border-bottom: 2px solid ;
      border-right: 2px solid ;
      transform: rotate(-45deg);
      transition: 0.2s;
      // color: red;
    }

    &--delivered {
      background-color: $green2;
      .xgs-consignee-accordion__button__text__consignee {
        color: $gray3;
        font-size: 22px;
      }
      .xgs-consignee-accordion__button__text__number {
        color: $gray3;
      }
      .xgs-consignee-accordion__button__text__exception {
        color: $lightred1;
      }
      &:hover {
        background-color: $green3;
      }
      &:before {
        color: $gray3;
      }
    }

    &[aria-expanded='true']{
      border-radius: $border-radius $border-radius 0px 0px;
    }

    &[aria-expanded='true']::before {
      transform: rotate(45deg);
    }

    &__actions {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__panel {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0px 0px $border-radius $border-radius;
    animation: fadein 0.35s ease-in;
  }
  
  &__sign {
    margin: auto;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    height: fit-content;
    span {
      font-size: 10px;
    }
  } 
}

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}