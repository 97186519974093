@import "./../../styles/variables.scss";

.xgs-btn {
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  height: 40px;
  width: 140px;
  font-size: 16px;
  transition: background-color 0.2s;

  &:focus {
    border: none;
    border: none;
    outline: none;
    border: none;
    outline: none;
    box-shadow: 0 0 0 2pt rgba(52, 182, 255, 0.5);
  }

  &:disabled {
    background: $gray5;
    color: $gray4;
    cursor: default;
  }

  &__spinner {
    margin-right: 8px;
  }

  &-small {
    padding: 4px 7px;
    width: auto;
    font-size: 14px;
  }

  &-medium {
    height: $margin5;
  }

  &-auto {
    width: auto;
  }

  &-blue {
    background: $blue1;
    color: white;

    &:hover:enabled {
      background: $blue2;
    }
  }

  &-gray {
    background-color: $gray6;
    color: $gray2;

    &:hover:enabled {
      background: $gray5;
    }
  }

  &-white {
    background-color: white;
    color: $gray3;

    &:hover:enabled {
      background-color: #e9e9e9;
    }
  }

  &-green {
    background-color: $green-button;
    color: white;

    &:hover:enabled {
      background-color: $green1;
    }
  }

  &-red {
    background-color: $red-button;
    color: white;

    &:hover:enabled {
      background-color: $red;
    }
  }

  &--spinner {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:disabled.xgs-btn-blue {
      background: $blue1;
      color: white;
    }
  }
}