@import "./../../styles/variables.scss";

.xgs-dropdown-menu-content {
  padding: $margin8 0 !important;
  width: auto !important;
  min-width: 150px;
  background-color: white;

  a {
    color: $gray0 !important;
  }

  .xgs-dropdown-menu__item {
    display: flex;
    align-items: center;
    padding: 0 0 0 4px;
    height: $margin6;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: $gray6;
    }

    .xgs-dropdown-menu__icon {
      width: $margin5;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }
  }
}