@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.xgs-complete-pickup {
  &__submit {
    margin-top: $margin8;
    width: 200px;
    @include up-to(601px) {
      width: 100%;
    }
  }

  &__probill-input {
    width: 200px;
    margin-bottom: $margin8;
  }

  &__special {
    font-size: 14px;
  }
}