@import "./../../../styles/variables.scss";

.xgs-textarea {
  position: relative;

  &__label {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 3px;

    span {
      color: $form-validation-error-color;
    }
  }

  &__counter {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 4px;
    font-size: 12px;
    line-height: 1;
    color: $gray2;

    &--error {
      color: $red;
    }
  }

  textarea {
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    min-height: 94px;
    transition: all 100ms;
    font-size: 14px;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(52, 182, 255, 0.5) !important;
    }

    &.xgs-textarea__invalid {
      border-color: $red;
      box-shadow: none;
    }
  }
}
