@import "./../../styles/variables.scss";

.xgs-page-loading {
  overflow: hidden;
  position: fixed;
  width: calc(100% + 32px);
  margin: 0 0 -16px -16px;
  top: $header-height;
  height: $height-without-header;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $content-page-loading-background;
  z-index: 1;
}
