@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

$tablet : 601px;

.xgs-stop-details {
  margin: auto;
  max-width: 640px;
  padding: 0;
  &__address {
    margin: 16px 24px;
    padding: 16px;
    border-radius: $border-radius;
    text-align: center;
    background-color: $gray7;
    color: $gray3;

    &__value {
      font-size: 28px;
      color: $darkblue1;
      @include up-to($tablet) {
        font-size: 24px;
      }
    }
  }

  &__summary {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0px 0 8px 0;
    width: 100%;
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 16px 32px 24px 32px;


      @include up-to($tablet) {
        margin: 16px 20px;
      }

      &__icon {
        color: $gray4;
        font-size: 36px;
        margin-right: 16px;

        @include up-to($tablet) {
          font-size: 32px;
          margin-right: 12px;
        }
      }

      &__text {
        color: $gray3;

        span {
          color: $darkblue1;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
  }

  &__roll {
    position: relative;
    display: grid;
    grid-template-columns: 60px repeat(9, 2fr); 
    padding: $margin7;
    font-size: 18px;
    column-gap: 10px;
    min-height: 60px;
    > * {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--refused {
      color: $gray3;
    }
    &__buttons {
      display: flex;
    }
    &__id {
      grid-column: span 4;
      &--exception {
        color: $red;
      }
    }
    &__desc {
      grid-column: span 3;
    }
    &__weight {
      grid-column: span 2;
      white-space: nowrap;
    }

  }
  &__sticky-header {
    position: fixed;
    top:0;
    text-align: center;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: 150ms;
    max-height: 0;
    background-color: rgba(255,255,255,1);
    overflow: hidden;

    &__show {
      max-height: 100px;
    }
  
  }
  &__fixed-header {
    text-align: left;
    margin-left: $margin8;
    padding: 5px;
    p {
      margin: 3px;
    }
  }

  &__no-stop {
    font-size: 16px;
    text-align: center;
    color: $red;
    margin-top: 10vh;
  }
}
