@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-route-map {
  display: flex;
  justify-content: center;
  padding-top: 24px;

  &__container {
    width: 800px;
    height: calc(100vh - 300px);
    max-width: calc(100vw - 48px);
    max-height: calc(100vh - 108px);
  }
}



// .xgs-map {
//   &__container {
//     width: 100%;
//     height: 600px;
//   }

//   &__marker {
//     position: absolute;
//     transform: translate(-50%, -50%);
//   }
// }
