@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

$logo-container-width: 80px;

.xgs-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  height: $header-height;
  background-color: $header-back;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  &__start {
    flex-shrink: 0;
    width: calc((100% - #{$logo-container-width}) / 2);
  }

  &__middle {
    width: $logo-container-width;
  }

  &__end {
    width: calc((100% - #{$logo-container-width}) / 2);
    flex: 3;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    @include up-to(600px) {
      flex: none;
      padding-right: 0px;
    }
  }

  .xgs-logo {
    display: block;
    margin: 0 auto;
    height: 54px;
    width: auto;
    max-width: 100%;

    @include mobile {
      width: 100px;
      height: auto;
      max-height: 54px;
    }
  }
}